import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10rem;
`;
export const UploadCard = styled.div`
  margin: 0.5em;
  font-family: oswald;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px 1px #a3d4cb;
  padding: 1em;
  border-radius: 10px;
  background: white;
  @media screen and (min-width: 320px) {
    width: 90vw;
  }
  @media screen and (min-width: 768px) {
    width: 80vw;
  }
  @media screen and (min-width: 1024px) {
    max-width: 70vw;
  }
  @media screen and (min-width: 1440px) {
    max-width: 50vw;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width:100%;
`;

export const ImgCont = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  //margin-bottom:1em;
  }
`;
export const ImgContM = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 1em;

  @media screen and (min-width: 768px) {
    display:none;
  }
`;
export const UploadImg = styled.img`
  width: 100%;
`;
export const Select = styled.select`
  width: 100%;
  margin: 0 0 10px;
  min-height: 40px;
`;
export const SelectLabel = styled.span`
  align-self: flex-start;
`;
